import ApiSistema from '@/core/conectores/ApiSistema';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { IPropriedadeConsulta } from '@/core/models/Consulta/PropriedadeConsulta';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IDTOPedido } from '@/models/DTO/Pedidos/IDTOPedido';
import { IPedido } from '@/models/Entidades/IPedido';
import { EStatusPedido } from '@/models/Enumeradores/EStatusPedido';
import { IParametrosConsultaPedido } from '@/models/ParametrosRequisicao/IParametrosConsultaPedido';

/**
 * Serviço de Pedidos
 * Fornece todas regras de negócios e lógicas relacionado os pedidos.
 */

class ServicoPedido {
    private endPoint = 'pedidos';

    private apiSistema = new ApiSistema();

    public async obterPropriedadesConsulta(): Promise<IPropriedadeConsulta[]> {
        const listaPropriedades = await this.apiSistema.obterPropriedadesConsulta(this.endPoint);
        return listaPropriedades;
    }

    public async obter(codigo: number): Promise<IPedido> {
        const result: any = await this.apiSistema.get(`${this.endPoint}/${codigo}`);
        return result.data;
    }

    public async obterPedidos(parametrosConsulta: IParametrosConsultaPedido, filtros?: IFiltroGenerico[]): Promise<IDTOPedido[]> {
        let parametrosAdicionais = '';
        if (UtilitarioGeral.validaCodigo(parametrosConsulta.integracao)) {
            parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
            parametrosAdicionais += `Integracao=${parametrosConsulta.integracao}`;
        }

        if (filtros !== undefined) {
            const jsonFiltros = JSON.stringify(filtros);
            parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
            parametrosAdicionais += `Filtros=${jsonFiltros}`;
        }
        if (parametrosConsulta.ordenacao !== undefined) {
            parametrosConsulta.ordenacao.forEach((item) => {
                parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
                parametrosAdicionais += `Ordenacao=${item}`;
            });
        }
        if (parametrosConsulta.codigosSelecionados !== undefined) {
            parametrosConsulta.codigosSelecionados.forEach((codigo) => {
                parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
                parametrosAdicionais += `CodigosSelecionados=${codigo}`;
            });
        }

        if (parametrosConsulta.periodo !== undefined) {
            parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
            parametrosAdicionais += `periodo=${parametrosConsulta.periodo}`;

            parametrosAdicionais += `&dataInicial=${parametrosConsulta.dataInicial}`;
            parametrosAdicionais += `&dataFinal=${parametrosConsulta.dataFinal}`;
        }

        if (UtilitarioGeral.valorValido(parametrosConsulta.buscaRapida)) {
            parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
            parametrosAdicionais += `buscaRapida=${parametrosConsulta.buscaRapida}`;
        }

        const result: any = await this.apiSistema.get(`${this.endPoint}${parametrosAdicionais}`);
        return result.data;
    }

    public obtemDescricaoStatus(status: EStatusPedido): string {
        let descricao = '';
        switch (status) {
            case EStatusPedido.Pendente:
                descricao = 'Pendente';
                break;

            case EStatusPedido.PagamentoConfirmado:
                descricao = 'Pagamento Confirmado';
                break;

            case EStatusPedido.Faturado:
                descricao = 'Faturado';
                break;

            case EStatusPedido.Enviado:
                descricao = 'Enviado';
                break;

            case EStatusPedido.Concluido:
                descricao = 'Concluído';
                break;

            case EStatusPedido.Cancelado:
                descricao = 'Cancelado';
                break;

            default:
                break;
        }

        return descricao;
    }
}
export default ServicoPedido;
