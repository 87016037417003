import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_range_picker = _resolveComponent("a-range-picker")!

  return (_openBlock(), _createBlock(_component_a_range_picker, {
    value: _ctx.state.datasSelecionadas,
    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.datasSelecionadas) = $event)),
    class: "ss-tamanho-completo",
    "show-time": (_ctx.props.horaMinuto || _ctx.props.horaMinutoSegundo),
    format: _ctx.obterFormatoData(),
    placeholder: _ctx.props.placeholder,
    disabled: _ctx.props.disabled,
    onChange: _ctx.change
  }, null, 8, ["value", "show-time", "format", "placeholder", "disabled", "onChange"]))
}