
import { computed, defineComponent } from 'vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';

export default defineComponent({
  name: 'SelecionarData',
  props: {
    dataSelecionada: {
      type: String,
      default: '',
    },
    horaMinuto: {
      type: Boolean,
      default: false,
    },
    horaMinutoSegundo: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:dataSelecionada', 'update:horaMinutoSegundo', 'change', 'blur'],
  setup(props, { emit }) {
    function obterFormatoData() {
      if (props.horaMinutoSegundo) {
        return UtilitarioData.formatoDataDiaMesAnoHoraMinutoSegundo;
      } if (props.horaMinuto) {
        return UtilitarioData.formatoDataDiaMesAnoHoraMinuto;
      }
      return UtilitarioData.formatoDataDiaMesAno;
    }

    function obterData() {
      if (UtilitarioGeral.valorValido(props.dataSelecionada)) {
        if (UtilitarioData.obterAnoData(props.dataSelecionada) === 1901) {
          return undefined;
        }
        if (props.horaMinuto) {
          return UtilitarioData.converterDataHoraMinutoParaDayJs(props.dataSelecionada);
        } if (props.horaMinutoSegundo) {
          return UtilitarioData.converterDataHoraMinutoSegundoParaDayJs(props.dataSelecionada);
        }
        return UtilitarioData.converterDataParaDayJs(props.dataSelecionada);
      }

      return undefined;
    }

    const computedDataSelecionada = computed({
      get: () => obterData(),
      set: (valor: any) => {
        if (valor === undefined || valor === null) {
          emit('update:dataSelecionada', '');
        } else if (props.horaMinuto || props.horaMinutoSegundo) {
          emit('update:dataSelecionada', UtilitarioData.converterDataHoraMinutoSegundoJson(valor));
        } else {
          emit('update:dataSelecionada', UtilitarioData.converterDataJson(valor));
        }
      },
    });

    function blur() {
      emit('blur');
    }

    function change() {
      emit('change', props.dataSelecionada);
    }

    return {
      props,
      obterFormatoData,
      computedDataSelecionada,
      blur,
      change,
    };
  },
});
