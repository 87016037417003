import { vModelText as _vModelText, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_maska = _resolveDirective("maska")!

  return _withDirectives((_openBlock(), _createElementBlock("input", {
    type: "text",
    maxlength: "18",
    class: "ant-input",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedValor) = $event)),
    disabled: _ctx.props.disabled
  }, null, 8, _hoisted_1)), [
    [_vModelText, _ctx.computedValor],
    [_directive_maska, _ctx.UtilitarioMascara.formatoMascaraCPFeCNPJ]
  ])
}