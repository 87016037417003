
  import {
    computed, defineComponent,
  } from 'vue';

  export default defineComponent({
    name: 'SelecionarStatusPagamento',
    components: {
    },
    props: {
      valor: {
        type: Number,
        required: true,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      classCss: {
        type: String,
        default: '',
      },
      dataAttributeColuna: {
        type: String,
        default: '',
      },
    },
    emits: ['update:valor', 'change'],
    setup(props, { emit }) {
      const computedValor = computed({
        get: () => props.valor,
        set: (valor: number) => {
          emit('update:valor', valor);
        },
      });

      function defineValorSelecionado(valor: boolean) {
        emit('update:valor', valor);
        emit('change', valor);
      }

      return {
        props,
        computedValor,
        defineValorSelecionado,
      };
    },
  });
  