
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Card',
  props: {
    titulo: {
      type: String,
    },
    moverCardTitulo: {
      type: Boolean,
      default: false,
    },
    altura: {
      type: Number,
    },
  },
  setup(props) {
    return {
      props,
    };
  },
});
