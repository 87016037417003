
import {
    defineComponent, reactive, watch,
} from 'vue';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useGradeBase } from '@/core/composables/GradeBase';
import { useModalBase } from '@/core/composables/ModalBase';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import Card from '@/core/components/Tela/Card.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import CampoCep from '@/core/components/Tela/CampoCep.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import SelecionarData from '@/core/components/Tela/SelecionarData.vue';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';
import { IPedido } from '@/models/Entidades/IPedido';
import ServicoPedido from '@/servicos/ServicoPedido';
import SelecionarIntegracao from '@/components/Integracoes/SelecionarIntegracao.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import CampoCpfCnpj from '@/core/components/Tela/CampoCpfCnpj.vue';
import CampoTelefone from '@/core/components/Tela/CampoTelefone.vue';
import SelecionarTipoPagamento from '@/components/Pedidos/SelecionarTipoPagamento.vue';
import SelecionarStatusPagamento from '@/components/Pedidos/SelecionarStatusPagamento.vue';

export default defineComponent({
    name: 'PedidoModal',
    props: {
        visivel: {
            type: Boolean,
        },
        codigo: {
            type: Number,
            required: true,
        },
    },
    components: {
        RequisicaoModal,
        Card,
        Icone,
        CampoNumerico,
        CampoCep,
        SelecionarData,
        SelecionarIntegracao,
        MensagemSemDados,
        CampoCpfCnpj,
        CampoTelefone,
        SelecionarTipoPagamento,
        SelecionarStatusPagamento,
    },
    emits: ['update:visivel', 'confirmacao'],
    setup(props, { emit }) {
        const servicoPedido = new ServicoPedido();

        const { telaBase } = useTelaBase();
        const { gradeBase } = useGradeBase();
        const { modalBase, apresentarRetornoRequisicao } = useModalBase(props, emit);

        telaBase.identificadorRecurso = 'PEDIDO';

        const state = reactive({
            pedido: {} as IPedido,
            telaMobile: false,
            carregando: false,
            carregandoItens: false,
        });

        async function limparTela() {
            state.pedido = {} as IPedido;
            state.pedido.codigo = 0;
            state.pedido.itens = [];
            state.carregando = false;
        }

        function preencherColunas() {
            if (state.telaMobile) {
                gradeBase.colunas = [
                    {
                        title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: false,
                    },
                    {
                        title: 'Item', dataIndex: 'item', key: 'Item', position: 2, visible: true, ellipsis: true,
                    },
                ];
            } else {
                gradeBase.colunas = [
                    {
                        title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: false,
                    },
                    {
                        title: 'Código do Anúncio', dataIndex: 'codigoAnuncio', key: 'CodigoAnuncio', position: 1, visible: true, width: 70, ordering: false, align: 'right', ellipsis: true,
                    },
                    {
                        title: 'Código Interno', dataIndex: 'codigoInterno', key: 'CodigoInterno', position: 2, visible: true, width: 70, ordering: false, align: 'right', ellipsis: true,
                    },
                    {
                        title: 'Produto', dataIndex: 'produto', key: 'Produto', position: 3, visible: true, width: 290, ordering: false, align: 'left', ellipsis: true,
                    },
                    {
                        title: 'Quantidade', dataIndex: 'quantidade', key: 'Quantidade', position: 4, visible: true, ordering: false, align: 'right', width: 50, ellipsis: true,
                    },
                    {
                        title: 'Valor Unitário', dataIndex: 'valorUnitario', key: 'ValorUnitario', position: 5, visible: true, ordering: false, align: 'right', width: 60, ellipsis: true,
                    },
                    {
                        title: 'Desconto', dataIndex: 'valorDesconto', key: 'ValorDesconto', position: 8, visible: true, ordering: false, align: 'right', width: 40, ellipsis: true,
                    },
                    {
                        title: 'Valor Total', dataIndex: 'valorTotal', key: 'ValorTotal', position: 9, visible: true, ordering: false, align: 'right', width: 70, ellipsis: true,
                    },
                ];
            }
        }

        watch(async () => modalBase.computedVisivel, async () => {
            state.telaMobile = UtilitarioDispositivo.larguraTelaMobile();
            limparTela();
            if (modalBase.computedVisivel) {
                telaBase.carregando = true;
                preencherColunas();
                state.pedido = await servicoPedido.obter(props.codigo);
                telaBase.carregando = false;
            }
        });

        return {
            props,
            state,
            telaBase,
            gradeBase,
            modalBase,
            UtilitarioGeral,
            UtilitarioMascara,
            apresentarRetornoRequisicao,
            preencherColunas,
        };
    },
});
