import { reactive } from 'vue';
import { IBuscaAvancada } from '../models/BuscaAvancada/IBuscaAvancada';
import { IPagination } from '../models/AntDesign/IPagination';
import { IListaPaginadaMetaData } from '../models/Consulta/IListaPaginada';
import { IColumn } from '../models/AntDesign/IColumn';
import { IOrdenacao } from '../models/Consulta/IOrdenacao';
import { IFiltroGenerico } from '../models/BuscaAvancada/IFiltroGenerico';
import { IPropriedadeConsulta } from '../models/Consulta/PropriedadeConsulta';
import UtilitarioMascara from '../utilitarios/UtilitarioMascara';
import UtilitarioGeral from '../utilitarios/UtilitarioGeral';
import { IPreferencia } from '@/models/Entidades/Sistema/Preferencias/Preferencia';
import { IParametrosConsulta } from '../models/Consulta/IParametrosConsulta';
import { EStatusRetornoRequisicao } from '../models/IRetornoRequisicao';
import { useTelaBase } from './TelaBase';
import ServicoSistema from '@/servicos/Sistema/ServicoSistema';

export interface IGradeBase {
  buscarDados: number;
  buscandoDados: boolean;
  nenhumRegistroEncontrado: boolean;
  propriedadesConsulta: Array<IPropriedadeConsulta>;
  buscaAvancada: IBuscaAvancada;
  paginacao: IPagination;
  paginacaoApi: IListaPaginadaMetaData;
  timerRegistros: number;
  totalRegistrosComMascara: string;
  colunasPadrao: IColumn[];
  colunas: IColumn[];
  colunasMobile: IColumn[];
  visibilidadeColunaAcoes: boolean,
  ordenacaoSelecionada: Array<IOrdenacao>;
  filtrosAplicados: Array<IFiltroGenerico>;
  codigosSelecionados: Array<number>;
  windowWidth: number;
  windowHeight: number;
  layoutMobile: boolean;
}
export interface IRetornoGradeBase {
  gradeBase: IGradeBase;
  defineValoresIniciaisGrade(): Promise<void>;
  preenchePropriedadesNosFiltrosEOrdenacao(): Promise<void>;
  preencheOrdenacaoSelecionada(key: string, sort: string): void;
  ordenacaoAtiva(key: string, sort: string): boolean;
  ordenarDados(key: string, sort: string): void;
  mudarPagina(current: number, pageSize: number): void;
  mudarQuantidadeRegistrosPorPagina(current: number, pageSize: number): void;
  aplicarFiltros(): void;
  preencheCodigosSelecionados(codigosSelecionados: any): void;
  alterarQuantidadeRegistroTotal(valor: any): void;
  defineAlturaScroll(percentual: number): number;
  obtemLarguraGrade(larguraMobile?: number): number;
  carregarPreferenciasGrade(preferencias: IPreferencia[]): void;
  preparaParametrosConsulta(contratantes: number[], numeroPagina: number, qtdeRegistrosPagina: number, qtdeRegistrosTotal: number): IParametrosConsulta;
  salvarFiltrosBuscaAvancada(empresas: number[]): Promise<void>;
  salvarOrdenacaoBuscaAvancada(empresas: number[]): Promise<void>;
  salvarPersonalizacaoColunas(empresas: number[]): Promise<void>;
  redefinirPersonalizacaoColunas(empresas: number[]): Promise<void>;
  salvarTodasPreferencias(empresas: number[]): Promise<void>;
}
export function useGradeBase(): IRetornoGradeBase {
  const gradeBase = reactive({
    buscarDados: 0,
    buscandoDados: false,
    nenhumRegistroEncontrado: false,
    propriedadesConsulta: [] as IPropriedadeConsulta[],
    buscaAvancada: {} as IBuscaAvancada,
    paginacao: {} as IPagination,
    paginacaoApi: { totalRegistros: 0, paginaAtual: 1 } as IListaPaginadaMetaData,
    timerRegistros: 0,
    totalRegistrosComMascara: '100',
    colunasPadrao: [] as IColumn[],
    colunas: [] as IColumn[],
    colunasMobile: [] as IColumn[],
    visibilidadeColunaAcoes: true,
    ordenacaoSelecionada: [] as IOrdenacao[],
    filtrosAplicados: [] as IFiltroGenerico[],
    codigosSelecionados: [] as number[],
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
    layoutMobile: false,
  });

  gradeBase.buscaAvancada.filtrosAdicionados = [];
  gradeBase.buscaAvancada.chaveFiltrosAdicionados = 0;
  gradeBase.ordenacaoSelecionada = [];

  const {
    montaObjetoPreferencia, salvarPreferencias, apresentarMensagemSucesso, apresentarMensagemAlerta,
  } = useTelaBase();

  async function defineValoresIniciaisGrade() {
    gradeBase.paginacao.total = 100;
    gradeBase.paginacao.showSizeChanger = true;
    gradeBase.paginacao.pageSize = 10;
    gradeBase.paginacao.pageSizeOptions = ['10', '50', '100', '500', '1000'];
  }

  async function preenchePropriedadesNosFiltrosEOrdenacao() {
    if (gradeBase.propriedadesConsulta.length > 0) {
      gradeBase.buscaAvancada.filtros = gradeBase.propriedadesConsulta.filter((propriedade) => propriedade.filtro === true);
      gradeBase.buscaAvancada.ordenacao = gradeBase.propriedadesConsulta.filter((propriedade) => propriedade.ordenacao === true);
    }
  }

  function preencheOrdenacaoSelecionada(key: string, sort: string) {
    if (gradeBase.ordenacaoSelecionada.length > 0) {
      const itemOrdem = gradeBase.ordenacaoSelecionada.find(((coluna) => coluna.identificador === key));
      if (itemOrdem === undefined) {
        gradeBase.ordenacaoSelecionada.push({ identificador: key, ordem: sort } as IOrdenacao);
      } else if (itemOrdem.ordem === sort) {
        const indice = gradeBase.ordenacaoSelecionada.indexOf(itemOrdem);
        gradeBase.ordenacaoSelecionada.splice(indice, 1);
      } else {
        itemOrdem.ordem = sort;
      }
    } else {
      gradeBase.ordenacaoSelecionada.push({ identificador: key, ordem: sort } as IOrdenacao);
    }
  }

  function ordenacaoAtiva(key: string, sort: string): boolean {
    if (gradeBase.ordenacaoSelecionada.length > 0) {
      const itemOrdem = gradeBase.ordenacaoSelecionada.find(((coluna) => coluna.identificador === key));
      if (itemOrdem === undefined) {
        return false;
      }
      if (itemOrdem.identificador === key && itemOrdem.ordem === sort) {
        return true;
      }
    }
    return false;
  }

  function ordenarDados(key: string, sort: string) {
    preencheOrdenacaoSelecionada(key, sort);
    gradeBase.buscarDados += 1;
  }

  function mudarPagina(current: number, pageSize: number) {
    gradeBase.paginacao.current = current;
    gradeBase.paginacao.pageSize = pageSize;
    gradeBase.buscarDados += 1;
  }

  function mudarQuantidadeRegistrosPorPagina(current: number, pageSize: number) {
    gradeBase.paginacao.current = current === 1 ? current : 1;
    gradeBase.paginacao.pageSize = pageSize;
    gradeBase.buscarDados += 1;
  }

  function aplicarFiltros() {
    gradeBase.paginacao.current = 1;
    gradeBase.buscarDados += 1;
  }

  function preencheCodigosSelecionados(codigosSelecionados: any) {
    gradeBase.codigosSelecionados = codigosSelecionados;
  }

  function alterarQuantidadeRegistroTotal(valor: any) {
    clearTimeout(gradeBase.timerRegistros);
    let totalRegistros = 100;
    gradeBase.timerRegistros = setTimeout(async () => {
      if (UtilitarioGeral.valorValido(valor)) {
        const limiteRegistros = UtilitarioMascara.removerMascaraNumero(valor);
        if (limiteRegistros > 0) {
          totalRegistros = limiteRegistros;
        }
      }
      gradeBase.paginacao.total = totalRegistros;
      gradeBase.totalRegistrosComMascara = UtilitarioMascara.mascararNumeroInteiro(totalRegistros);
      gradeBase.buscarDados += 1;
    }, 600);
  }

  function defineAlturaScroll(percentual: number): number {
    const altura = ((window.innerHeight / 100) * percentual);
    return altura;
  }
  function obtemLarguraGrade(larguraMobile?: number): number {
    if (window.innerWidth > 800) {
      return (window.innerWidth - 100);
    }
    if (larguraMobile !== undefined) {
      return larguraMobile;
    }
    return (window.innerWidth + 500);
  }

  function carregarPreferenciasGrade(preferencias: IPreferencia[]): void {
    if (preferencias !== null) {
      const preferenciaFiltros = preferencias.find(((preferencia) => preferencia.chave === 'busca-avancada-filtros'));
      if (preferenciaFiltros !== undefined && preferenciaFiltros !== null) {
        gradeBase.buscaAvancada.filtrosAdicionados = JSON.parse(preferenciaFiltros.valor);
        gradeBase.buscaAvancada.chaveFiltrosAdicionados = (gradeBase.buscaAvancada.filtrosAdicionados.length) + 1;
      }
      const preferenciaOrdenacao = preferencias.find(((preferencia) => preferencia.chave === 'busca-avancada-ordenacao'));
      if (preferenciaOrdenacao !== undefined && preferenciaOrdenacao !== null) {
        gradeBase.ordenacaoSelecionada = JSON.parse(preferenciaOrdenacao.valor);
      }
      const preferenciaColunas = preferencias.find(((preferencia) => preferencia.chave === 'personalizacao-colunas'));
      if (preferenciaColunas !== undefined && preferenciaColunas !== null) {
        if (preferenciaColunas.valor !== '[]' && preferenciaColunas.valor !== '') {
          const colunasDB: IColumn[] = JSON.parse(preferenciaColunas.valor);
          const colunasNovasPreferencias: IColumn[] = [];
          gradeBase.colunas.forEach((coluna) => {
            const preferenciaCol = colunasDB.find(((colunaDB) => colunaDB.key === coluna.key));
            const colunaNova: IColumn = coluna;
            if (preferenciaCol !== undefined && preferenciaCol !== null) {
              colunaNova.visible = preferenciaCol.visible;
              colunaNova.fixed = preferenciaCol.fixed;
              colunaNova.align = preferenciaCol.align;
              colunaNova.width = preferenciaCol.width;
              colunaNova.position = preferenciaCol.position;
              colunasNovasPreferencias.push(colunaNova);
            }
          });
          gradeBase.colunas = colunasNovasPreferencias.sort((x, y) => x.position - y.position);
        }
      } else {
        gradeBase.colunas = gradeBase.colunasPadrao;
      }
    }
  }

  function preparaParametrosConsulta(contratantes: number[], numeroPagina: number, qtdeRegistrosPagina: number, qtdeRegistrosTotal: number): IParametrosConsulta {
    gradeBase.filtrosAplicados = [];
    gradeBase.buscaAvancada.filtrosAdicionados.forEach((item) => {
      gradeBase.filtrosAplicados.push(item.filtro);
    });

    const parametrosConsulta = {} as IParametrosConsulta;
    parametrosConsulta.contratantes = contratantes;
    parametrosConsulta.numeroPagina = numeroPagina;
    parametrosConsulta.qtdeRegistrosPagina = qtdeRegistrosPagina;
    parametrosConsulta.qtdeRegistrosTotal = qtdeRegistrosTotal;
    parametrosConsulta.ordenacao = Array<string>();

    if (UtilitarioGeral.validaLista(gradeBase.ordenacaoSelecionada)) {
      gradeBase.ordenacaoSelecionada.forEach((item) => {
        parametrosConsulta.ordenacao.push(`${item.identificador}|${item.ordem}`);
      });
    }

    return parametrosConsulta;
  }

  async function salvarFiltrosBuscaAvancada(empresas: number[]): Promise<void> {
    await salvarPreferencias('Filtros salvos com sucesso!', 'busca-avancada-filtros',
      JSON.stringify(gradeBase.buscaAvancada.filtrosAdicionados), empresas);
  }

  async function salvarOrdenacaoBuscaAvancada(empresas: number[]): Promise<void> {
    await salvarPreferencias('Ordenação salva com sucesso!', 'busca-avancada-ordenacao',
      JSON.stringify(gradeBase.ordenacaoSelecionada), empresas);
  }

  async function salvarPersonalizacaoColunas(empresas: number[]): Promise<void> {
    const colunasPersistencia: IColumn[] = []; let posicao = 0;
    gradeBase.colunas.forEach((coluna) => {
      const colunaNova = coluna;
      colunaNova.position = posicao;
      colunasPersistencia.push(colunaNova);
      posicao += 1;
    });
    await salvarPreferencias('Personalizações salvas com sucesso!', 'personalizacao-colunas',
      JSON.stringify(colunasPersistencia.sort((c) => c.position)), empresas);
  }

  async function redefinirPersonalizacaoColunas(empresas: number[]): Promise<void> {
    const retorno = await new ServicoSistema().redefinirPreferenciaUsuario(montaObjetoPreferencia('personalizacao-colunas', '', empresas));
    if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
      gradeBase.colunas = gradeBase.colunasPadrao;
      apresentarMensagemSucesso('Personalizações redefinidas com sucesso!');
    } else {
      apresentarMensagemAlerta(retorno.mensagem);
    }
  }

  async function salvarTodasPreferencias(empresas: number[]): Promise<void> {
    await salvarFiltrosBuscaAvancada(empresas);
    await salvarOrdenacaoBuscaAvancada(empresas);
    await salvarPersonalizacaoColunas(empresas);
  }

  return {
    gradeBase,
    defineValoresIniciaisGrade,
    preenchePropriedadesNosFiltrosEOrdenacao,
    preencheOrdenacaoSelecionada,
    ordenacaoAtiva,
    mudarPagina,
    mudarQuantidadeRegistrosPorPagina,
    aplicarFiltros,
    ordenarDados,
    preencheCodigosSelecionados,
    alterarQuantidadeRegistroTotal,
    defineAlturaScroll,
    obtemLarguraGrade,
    carregarPreferenciasGrade,
    preparaParametrosConsulta,
    salvarFiltrosBuscaAvancada,
    salvarOrdenacaoBuscaAvancada,
    salvarPersonalizacaoColunas,
    redefinirPersonalizacaoColunas,
    salvarTodasPreferencias,

  };
}
