
import {
    defineComponent, onBeforeMount, reactive, watch,
} from 'vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import TituloPadrao from '@/core/components/Tela/TituloPadrao.vue';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import { IBuscaAvancada } from '@/core/models/BuscaAvancada/IBuscaAvancada';
import { ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import storeSistema from '@/store/storeSistema';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { useGradeBase } from '@/core/composables/GradeBase';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import { IFiltroGenericoAdicionado } from '@/core/models/BuscaAvancada/IFiltroGenericoAdicionado';
import SelecionarDataInicialFinal from '@/core/components/Tela/SelecionarDataInicialFinal.vue';
import { EFiltroPeriodo } from '@/models/Enumeradores/EFiltroPeriodo';
import ServicoSistema from '@/servicos/Sistema/ServicoSistema';
import ServicoPedido from '@/servicos/ServicoPedido';
import { IParametrosConsultaPedido } from '@/models/ParametrosRequisicao/IParametrosConsultaPedido';
import { IDTOPedido } from '@/models/DTO/Pedidos/IDTOPedido';
import SelecionarIntegracao from '@/components/Integracoes/SelecionarIntegracao.vue';
import SelecionarFitroPeriodo from '@/components/SeleciontarFiltroPeriodo.vue';
import TotalizadorDecorado from '@/core/components/Tela/TotalizadorDecorado.vue';
import { EStatusPedido } from '@/models/Enumeradores/EStatusPedido';
import PedidoModal from './PedidoModal.vue';

export default defineComponent({
    name: 'PedidoIndex',
    components: {
        Icone,
        Card,
        MensagemSemDados,
        SelecionarIntegracao,
        SelecionarFitroPeriodo,
        SelecionarDataInicialFinal,
        TituloPadrao,
        TotalizadorDecorado,
        PedidoModal,
    },
    setup(props) {
        const servicoPedido = new ServicoPedido();
        const servicoSistema = new ServicoSistema();
        let debounce = 0;
        const {
            telaBase, apresentarMensagemAlerta,
            adicionarAtalho, removerAtalho,
        } = useTelaBase();
        const { gradeBase } = useGradeBase();

        const state = reactive({
            buscaAvancada: {} as IBuscaAvancada,
            parametrosConsulta: {} as IParametrosConsultaPedido,
            apresentarResultados: false,
            dataAtual: '',
            pedidos: [] as IDTOPedido[],
            codigoPedido: 0,
            apresentarModalPedido: false,
        });

        state.parametrosConsulta.periodo = EFiltroPeriodo.Hoje;

        function preencherColunas() {
            if (storeSistema.state.layoutMobile) {
                gradeBase.colunas = [
                    {
                        title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: false,
                    },
                    {
                        title: 'Pedido', dataIndex: 'pedido', key: 'Pedido', position: 2, visible: true, ordering: true, align: 'left',
                    },
                ];
            } else {
                gradeBase.colunas = [
                    {
                        title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: false,
                    },
                    {
                        title: 'N.Pedido', dataIndex: 'numeroPedido', key: 'NumeroPedido', position: 2, visible: true, ellipsis: true, width: 30, customRenderRow: ECustomRenderRow.LinkAcao, fixed: 'left',
                    },
                    {
                        title: 'Data', dataIndex: 'dataCriacao', key: 'DataCriacao', position: 3, visible: true, ordering: true, customRenderRow: ECustomRenderRow.MascararData, width: 30, align: 'center',
                    },
                    {
                        title: 'Cliente', dataIndex: 'cliente', key: 'Cliente', position: 4, visible: true, ellipsis: true, width: 60,
                    },
                    {
                        title: 'Valor Total R$', dataIndex: 'valorTotal', key: 'ValorTotal', position: 5, visible: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 40,
                    },
                    {
                        title: 'Status', dataIndex: 'statusPedido', key: 'StatusPedido', position: 6, visible: true, ellipsis: true, width: 50,
                    },
                ];
            }
        }

        function obterFiltros() {
            switch (state.parametrosConsulta.periodo) {
                case EFiltroPeriodo.Hoje:
                    state.parametrosConsulta.dataInicial = state.dataAtual;
                    state.parametrosConsulta.dataFinal = state.dataAtual;
                    break;

                case EFiltroPeriodo.MesAtual:
                    state.parametrosConsulta.dataInicial = UtilitarioData.obterPrimeiroDiaMes(state.dataAtual);
                    state.parametrosConsulta.dataFinal = UtilitarioData.obterUltimoDiaMes(state.dataAtual);
                    break;

                case EFiltroPeriodo.AnoAtual:
                    state.parametrosConsulta.dataInicial = UtilitarioData.obterPrimeiroDiaAno(state.dataAtual);
                    state.parametrosConsulta.dataFinal = UtilitarioData.obterUltimoDiaAno(state.dataAtual);
                    break;

                case EFiltroPeriodo.Ultimos7Dias:
                    state.parametrosConsulta.dataInicial = UtilitarioData.diminuirDiaNaData(state.dataAtual, 7);
                    state.parametrosConsulta.dataFinal = state.dataAtual;
                    break;

                case EFiltroPeriodo.Ultimos15Dias:
                    state.parametrosConsulta.dataInicial = UtilitarioData.diminuirDiaNaData(state.dataAtual, 15);
                    state.parametrosConsulta.dataFinal = state.dataAtual;
                    break;

                case EFiltroPeriodo.Ultimos30Dias:
                    state.parametrosConsulta.dataInicial = UtilitarioData.diminuirDiaNaData(state.dataAtual, 30);
                    state.parametrosConsulta.dataFinal = state.dataAtual;
                    break;

                case EFiltroPeriodo.Ultimos60Dias:
                    state.parametrosConsulta.dataInicial = UtilitarioData.diminuirDiaNaData(state.dataAtual, 60);
                    state.parametrosConsulta.dataFinal = state.dataAtual;
                    break;

                case EFiltroPeriodo.Ultimos90dias:
                    state.parametrosConsulta.dataInicial = UtilitarioData.diminuirDiaNaData(state.dataAtual, 90);
                    state.parametrosConsulta.dataFinal = state.dataAtual;
                    break;

                default:
            }

            state.buscaAvancada.filtrosAdicionados = [] as IFiltroGenericoAdicionado[];

            gradeBase.filtrosAplicados = [];
            state.buscaAvancada.filtrosAdicionados.forEach((item) => {
                gradeBase.filtrosAplicados.push(item.filtro);
            });
        }

        async function buscarPedidos() {
            state.pedidos = [];
            state.apresentarResultados = false;

            if (state.parametrosConsulta.periodo === EFiltroPeriodo.Personalizado && !UtilitarioGeral.valorValido(state.parametrosConsulta.dataInicial)) {
                apresentarMensagemAlerta('A data inicial deve ser informada!');
                return;
            }

            if (state.parametrosConsulta.periodo === EFiltroPeriodo.Personalizado && !UtilitarioGeral.valorValido(state.parametrosConsulta.dataFinal)) {
                apresentarMensagemAlerta('A data final deve ser informada!');
                return;
            }

            clearTimeout(debounce);
            debounce = setTimeout(async () => {
                state.parametrosConsulta.ordenacao = Array<string>();

                gradeBase.ordenacaoSelecionada.forEach((item) => {
                    state.parametrosConsulta.ordenacao.push(`${item.identificador}|${item.ordem}`);
                });

                if (!UtilitarioGeral.validaLista(gradeBase.ordenacaoSelecionada)) {
                    state.parametrosConsulta.ordenacao.push('DataCriacao|ASC');
                }

                telaBase.carregando = true;
                state.pedidos = await servicoPedido.obterPedidos(state.parametrosConsulta, gradeBase.filtrosAplicados);

                state.apresentarResultados = true;
                telaBase.carregando = false;
            }, 600);
            obterFiltros();
        }

        async function buscarPedidosPorStatus(status: EStatusPedido) {
            state.parametrosConsulta.status = status;
            await buscarPedidos();
        }
        async function verificarAlteracaoPeriodo() {
            if (state.parametrosConsulta.periodo !== EFiltroPeriodo.Personalizado) {
                await buscarPedidos();
            }
        }

        const mudarSelecao = (selectedRowKeys: any) => {
            gradeBase.codigosSelecionados = selectedRowKeys;
        };

        async function verificacaoFiltrosBoleanos() {
            preencherColunas();
            await buscarPedidos();
        }

        onBeforeMount(async () => {
            telaBase.carregando = true;

            telaBase.propriedadesConsulta = await servicoPedido.obterPropriedadesConsulta();
            if (telaBase.propriedadesConsulta.length > 0) {
                state.buscaAvancada.filtros = telaBase.propriedadesConsulta.filter((item) => item.filtro === true);
                state.buscaAvancada.ordenacao = telaBase.propriedadesConsulta.filter((item) => item.ordenacao === true);
            }
            state.buscaAvancada.filtrosAdicionados = [];
            state.buscaAvancada.chaveFiltrosAdicionados = 0;
            preencherColunas();

            state.dataAtual = await servicoSistema.obterDataAtual();
            state.dataAtual = UtilitarioData.converterDataJson(state.dataAtual);
            await buscarPedidos();
            telaBase.carregando = false;
        });

        watch(() => storeSistema.state.layoutMobile, () => {
            preencherColunas();
        });

        function textoCodigosSelecionados(): string {
            if (gradeBase.codigosSelecionados.length === 1) { return '1 - Pedido selecionado'; }

            if (gradeBase.codigosSelecionados.length > 1) { return `${gradeBase.codigosSelecionados.length} - Pedidos selecionados`; }

            return '';
        }

        function visualizar(codigo: number) {
            state.codigoPedido = codigo;
            state.apresentarModalPedido = true;
        }

        function obtemValorTotalizador(status: EStatusPedido): number {
            let valor = 0;
            if (UtilitarioGeral.validaLista(state.pedidos)) {
                const pedidos = state.pedidos.filter((c) => c.statusPedido === status);
                if (UtilitarioGeral.validaLista(pedidos)) {
                    pedidos.forEach((pedido) => {
                        valor += pedido.valorTotal;
                    });
                }
            }
            return valor;
        }

        function obterCorStatus(status: EStatusPedido): string {
            let cor = '';
            switch (status) {
                case EStatusPedido.Pendente:
                    cor += '#ffeb3b';
                    break;

                case EStatusPedido.PagamentoConfirmado:
                    cor += '#87e22d';
                    break;

                case EStatusPedido.Faturado:
                    cor += '#2196f3';
                    break;

                case EStatusPedido.Enviado:
                    cor += '#ff9800';
                    break;

                case EStatusPedido.Concluido:
                    cor += '#4caf50';
                    break;

                case EStatusPedido.Cancelado:
                    cor += '#f44336';
                    break;

                default:
                    break;
            }
            return cor;
        }

        return {
            state,
            props,
            window,
            ECustomRenderRow,
            EFiltroPeriodo,
            UtilitarioMascara,
            UtilitarioData,
            storeSistema,
            telaBase,
            gradeBase,
            buscarPedidos,
            buscarPedidosPorStatus,
            mudarSelecao,
            adicionarAtalho,
            removerAtalho,
            preencherColunas,
            verificarAlteracaoPeriodo,
            verificacaoFiltrosBoleanos,
            textoCodigosSelecionados,
            visualizar,
            EStatusPedido,
            obtemValorTotalizador,
            obterCorStatus,
            servicoPedido,
        };
    },
});
